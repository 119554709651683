<template>
    <div style="margin-bottom:2rem;">
        <v-snackbar v-model="alert.visiable" :timeout="1000" :value="true" absolute top :color="alert.color" centered
            text>
            {{alert.msg}}
        </v-snackbar>
        <p class="display-3">{{data.title}}</p>
        <p class="caption">{{data.create_time}}</p>
        <p>
            <span>阅读</span>
            <span>{{data.read_count}}</span>
            <span class="ml-5">评论</span>
            <span>{{data.comments_length}}</span>
            <span class="ml-5">喜欢</span>
            <span>{{data.stars}}</span>
        </p>
        <v-row>
            <v-col>
                <!-- <mavon-editor id="editor" v-show="showEditor" style="min-height: 600px;" v-model="data.content"
                    :subfield="false" :defaultOpen="'preview'" @change="refresh" :boxShadow="false"
                    :toolbarsFlag="false" :editable="false" :scrollStyle="true" :ishljs="true" /> -->
                
                <x-show
                :show="true"
                >
                <div v-html="html" class="text-left"></div>
                </x-show>
            </v-col>
        </v-row>
        <v-row style="margin-top:2rem;">
            <v-col class="pt-0 pb-0" cols="12">
                <v-textarea v-model="comment_detail" label="请输入您的评论" auto-grow outlined rows="5" row-height="15">
                </v-textarea>
            </v-col>

            <v-col cols="12">
                <v-btn icon color="deep-orange" @click="addStar">
                    <v-icon>mdi-thumb-up</v-icon>
                </v-btn>
                <span class="deep-orange--text">{{data.stars}}</span>
                <v-btn class="float-right" @click="doComment">提交</v-btn>
            </v-col>
            <v-col cols="12">
                <v-alert v-show="alert_for_comment.visiable" outlined dismissible :type="alert_for_comment.type" text>
                    {{alert_for_comment.msg}}
                </v-alert>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-for="item of comments" :key="item.id" cols="12">
                <v-card flat outlined class="rounded-lg">
                    <v-card-text>
                        <p v-html="item.comment_detail"></p>
                        <p class="text-right">{{item.create_time}}</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <div class="text-center mt-8" v-if="comments_total_page!=0">
            <v-pagination v-model="q.page" :length="comments_total_page" :total-visible="7"></v-pagination>
        </div>
    </div>
</template>
<style scoped>
    .mavonEditor {
        width: 100%;
        height: 100%;
    }
</style>
<script>
    // @ is an alias to /src
    //import HelloWorld from '@/components/HelloWorld.vue'
    import { mdiThumbUp } from "@mdi/js";
    import * as $ from "jquery"
    import showdown from "showdown"

    export default {
        name: 'detail',
        components: {
            //HelloWorld
        },
        data: function () {
            return {
                data: {},
                html:"",
                comment_detail: "",
                alert: {
                    visiable: false,
                    msg: "",
                    color: "red darken-2"
                },
                alert_for_comment: {
                    visiable: false,
                    msg: "",
                    type: "success"
                },
                comments: [],
                q: {
                    page: 1,
                    total: 10
                },
                comments_total_page:0,
                showEditor: false,
                icons: {
                    mdiThumbUp
                }
            }
        },
        mounted() {
            $(".v-show-content.scroll-style.scroll-style-border-radius").css("background-color", "white")
            $(".v-note-wrapper").css("border", "none")
            $(".v-show-content.scroll-style.scroll-style-border-radius").css("padding", 0)

        },
        created() {
            this.$emit("fnc")
            this.$emit("hideBackToTop")
            //解决抽屉无法覆盖markdown文本bug
            $(".v-navigation-drawer--temporary").css("z-index", 10000)
            $(".markdown-body").css("z-index", 0)
            this.getData()
        },
        methods: {
            getData() {
                //获取数据
                let id = this.$route.query.id
                console.log(id)
                let that = this
                this.axios.get(this.$store.state.base_url + "/article/" + id).then(function (response) {
                    console.log(response);
                    if (response.data.code == 200) {
                        that.data = response.data.data
                        var converter = new showdown.Converter(); 
                        converter.setFlavor('github');
                        var html = converter.makeHtml(response.data.data.content);
                        console.log(html)
                        //document.getElementById('show-content').innerHTML =html;
                        that.html = html
                    } else {
                        that.alert.msg = "获取数据失败"
                        that.alert.color = "red darken-2"
                        that.alert.visiable = true
                    }
                    console.log($(document).height())


                }).catch(function (error) {
                    that.alert.msg = error
                    that.alert.visiable = true
                })
                this.getComments()
            },
            getComments() {
                let that = this
                let id = this.$route.query.id
                this.axios.get(this.$store.state.base_url + "/comment/" + id + "?page=" + this.q.page).then(function (
                    response) {
                    console.log(response);
                    if (response.data.code == 200) {
                        let records = response.data.data.records
                        var converter = new showdown.Converter(); 
                        converter.setFlavor('github');
                        records.forEach(element => {
                            element.comment_detail = converter.makeHtml(element.comment_detail)
                        });
                        that.comments = records
                        that.q.total = response.data.data.total
                        that.comments_total_page = Math.ceil(that.q.total / 10)
                    } else {
                        that.alert.msg = "获取数据失败"
                        that.alert.color = "red darken-2"
                        that.alert.visiable = true
                    }

                }).catch(function (error) {
                    that.alert.msg = error
                    that.alert.color = "red darken-2"
                    that.alert.visiable = true
                })
            },
            refresh() {
                //alert($("#editor").css('height'))
                this.showEditor = true
                $("#editor").foucus()
                //this.$forceUpdate();
            },
            doComment() {
                let that = this
                let data = {
                    comment_detail: this.comment_detail,
                    article_id: this.$route.query.id
                }
                if (this.comment_detail == "") {
                    this.alert_warning("评论不可为空")
                }
                this.axios.post(this.$store.state.base_url + "/comment/front", data).then(function (response) {
                    console.log(response);
                    if (response.data.code == 200) {
                        that.alert_success("提交评论成功,审核通过后才可显示")
                        that.comment_detail = ""
                    } else {
                        that.alert_error("提交评论失败")
                    }

                }).catch(function (error) {
                    this.alert_error(error)
                })
            },
            alert_error(msg) {
                this.alert_for_comment.msg = msg
                this.alert_for_comment.type = "error"
                this.alert_for_comment.visiable = true
            },
            alert_success(msg) {
                this.alert_for_comment.msg = msg
                this.alert_for_comment.type = "success"
                this.alert_for_comment.visiable = true
            },
            alert_warning(msg) {
                this.alert_for_comment.msg = msg
                this.alert_for_comment.type = "warning"
                this.alert_for_comment.visiable = true
            },
            addStar() {
                let that = this
                this.axios.post(this.$store.state.base_url + "/star/front/" + this.$route.query.id).then(function (
                    response) {
                    console.log(response);
                    if (response.data.code == 200) {
                        that.data.stars += 1
                    } else {
                        that.alert_for_comment.msg = "点赞失败"
                        that.alert_for_comment.type = "error"
                        that.alert_for_comment.visiable = true
                    }

                }).catch(function (error) {
                    that.alert_for_comment.msg = error
                    that.alert_for_comment.type = "error"
                    that.alert_for_comment.visiable = true
                })
            }
        },
        updated() {
            //解决抽屉无法覆盖markdown文本bug
            $(".v-navigation-drawer--temporary").css("z-index", 10000)
            $(".markdown-body").css("z-index", 0)
            $("pre").css({
                "white-space":"pre-wrap",
                "border":"1px solid #dfdfdf",
                "background-color":"#f8f8f8",
                "margin-top":"1.5rem",
                "margin-bottom":"1.5rem",
                "padding":"1rem 1rem 1rem 1rem",
                "word-wrap":"break-word",

            })
            $("pre code").css({
                "font-family":"Consolas,'Liberation Mono',Courier,monospace"
            })
            $("h1,h2,h3,h4,h5,h6").css({
                "font-family":"'Roboto', sans-serif",
                "font-weight":"bold",
                "color":"#272727c7",
                "margin-bottom":"1rem"
            })
            $(".text-left img").css({
                "width":"100%"
            })
            $("hr").css({
                "margin-top":"1rem",
                "margin-bottom":"1rem"
            })
        },
        watch: {
            'q.page': {
                handler: function () {
                    this.getData()
                }
            },
        }
    }
</script>
<style scoped>
 pre{
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}
</style>